import Uppy, {
	UIPlugin,
	PluginOptions,
	UppyFile,
	UppyEventMap,
} from "@uppy/core";
import Tus from "@uppy/tus";

interface UppyVimeoTusPluginOptions extends PluginOptions {
	token: string;
	videoId: string;
	tusPluginId?: string;
}

const TUS_PLUGIN_ID = "Tus";

enum MESSAGE {
	UPLOAD_STARTED = "UPLOAD_STARTED",
	UPLOAD_COMPLETE = "UPLOAD_COMPLETE",
	UPLOAD_ERROR = "UPLOAD_ERROR",
	FILE_ADDED = "FILE_ADDED",
}

interface EventMessageConfig {
	event: keyof UppyEventMap;
	message: MESSAGE;
}

const EVENT_MESSAGES: EventMessageConfig[] = [
	{ event: "upload", message: MESSAGE.UPLOAD_STARTED },
	{ event: "complete", message: MESSAGE.UPLOAD_COMPLETE },
	{ event: "error", message: MESSAGE.UPLOAD_ERROR },
	{ event: "file-added", message: MESSAGE.FILE_ADDED },
];

/**
 * UppyVimeoTusPlugin creates a step prior to upload which makes a request to
 * the vimeo API to update the upload method to 'tus' and set the file size
 * This is required because when the upload was initially created (in AppSmith)
 * we don't know the file size; and due to their API structure can't set the
 * method to 'tus'
 * Started from example Uppy Plugin Here:
 * https://uppy.io/docs/writing-plugins/#Example-of-a-custom-plugin
 **/
class UppyVimeoTusPlugin extends UIPlugin<UppyVimeoTusPluginOptions> {
	opts: UppyVimeoTusPluginOptions;

	constructor(uppy: Uppy, opts: UppyVimeoTusPluginOptions) {
		const defaultOptions = { tusPluginId: "Tus" };
		super(uppy, { ...defaultOptions, ...opts });
		this.opts = opts;

		this.id = this.opts.id || "ImageCompressor";
		this.type = "modifier";

		uppy.use(Tus, {
			id: TUS_PLUGIN_ID,
			overridePatchMethod: false,
			// endpoint to be set in setTusEndpoint
		});

		EVENT_MESSAGES.forEach(({ event, message }) => {
			uppy.on(event, (e: any) => this.sendMessage(message, { event: e }));
		});
	}

	sendMessage = (message: MESSAGE, other = {}) => {
		if (!window.parent) return;
		window.parent.postMessage({ message, ...other }, "*");
	};

	getTusPlugin = () => {
		return this.uppy.getPlugin(TUS_PLUGIN_ID);
	};

	setTusEndpoint = (uploadUrl: string) => {
		const tus = this.getTusPlugin();
		if (!tus) {
			throw new Error("Tus not found");
		}
		// Note: need to use `uploadUrl` instead of `endpoint` here
		// in order to skip the "upload creation" step
		tus.setOptions({ uploadUrl });
	};

	prepareTusForFile = (
		file: UppyFile<Record<string, unknown>, Record<string, unknown>>
	) => {
		return fetch(`https://api.vimeo.com/videos/${this.opts.videoId}/versions`, {
			method: "POST",
			headers: {
				Authorization: `Bearer ${this.opts.token}`,
				"Content-Type": "application/json",
				Accept: "application/vnd.vimeo.*+json;version=3.4",
			},
			body: JSON.stringify({
				file_name: file.name,
				upload: {
					status: "in_progress",
					size: file.size,
					approach: "tus",
				},
			}),
		})
			.then((resp) => resp.json())
			.then((result) => {
				this.setTusEndpoint(result.upload.upload_link);
			});
	};

	prepareUpload = (fileIDs: string[]) => {
		if (fileIDs.length > 1) {
			throw new Error("Cannot upload more than one file to Vimeo");
		}

		const file = this.uppy.getFile(fileIDs[0]);
		this.uppy.emit("preprocess-progress", file, {
			mode: "indeterminate",
			message: "Preparing upload...",
		});

		return this.prepareTusForFile(file)
			.then(() => {
				this.uppy.emit("preprocess-complete", file);
			})
			.catch((err) => {
				console.error("Error preparing vimeo upload:", err);
				throw err;
			});
	};

	install() {
		this.uppy.addPreProcessor(this.prepareUpload);
	}

	uninstall() {
		this.uppy.removePreProcessor(this.prepareUpload);
	}
}

export default UppyVimeoTusPlugin;
