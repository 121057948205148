import React, { useCallback, useState } from "react";
import "./App.css";
import UploadArea from "./components/UploadArea";

const getParam = (name: string) => {
	const params = new URLSearchParams(window.location.search);
	return params.get(name);
};

function App() {
	const [done, setDone] = useState(false);

	const onComplete = useCallback(() => {
		if (!window.parent) {
			console.error("unable to post message");
			// Show fallback done state when unable to post messages
			setDone(true);
		}
	}, [setDone]);

	const token = getParam("token");
	const id = getParam("id");

	if (!token || !id) {
		return (
			<div className="message">
				<h1>An error ocurred, please close this and try again!</h1>
			</div>
		);
	}

	return (
		<div className="App">
			{!done ? (
				<UploadArea id={id} token={token} onComplete={onComplete} />
			) : (
				<div className="message">
					<h1>🎊 Upload complete! 🎊</h1>
					<p>Close this modal to continue</p>
				</div>
			)}
		</div>
	);
}

export default App;
