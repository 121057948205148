import React, { useEffect, useMemo } from "react";
import Tus from "@uppy/tus";

import Uppy from "@uppy/core";
import { Dashboard } from "@uppy/react";
import getUppyLogger from "../../utils/getUppyLogger";

import "@uppy/core/dist/style.css";
import "@uppy/dashboard/dist/style.css";

import "./UploadArea.css";
import UppyVimeoTusPlugin from "../../utils/UppyVimeoTusPlugin";

const UPPY_CONFIG = {
	meta: { type: "video" },
	logger: getUppyLogger(),
	allowMultipleUploadBatches: false,
	restrictions: {
		maxNumberOfFiles: 1,
		allowedFileTypes: ["video/*"],
	},
};

const DASHBOARD_CONFIG = {
	proudlyDisplayPoweredByUppy: false,
	showProgressDetails: true,
	width: "100vw",
	height: "100vh",
};

const useUppy = (id: string, token: string, onComplete: () => void) => {
	const uppy = useMemo(() => {
		const u = new Uppy({
			...UPPY_CONFIG,
		});
		u.use(UppyVimeoTusPlugin, { videoId: id, token });
		u.on("complete", onComplete);
		return u;
	}, [id, token, onComplete]);

	useEffect(() => {
		return () => uppy.close();
	}, [uppy]);

	return uppy;
};

interface UploadAreaProps {
	id: string;
	token: string;
	onComplete: () => void;
}

const UploadArea = ({ id, token, onComplete }: UploadAreaProps) => {
	const uppy = useUppy(id, token, onComplete);

	if (!uppy) return null;

	return <Dashboard {...DASHBOARD_CONFIG} uppy={uppy} plugins={[]} />;
};

export default UploadArea;
