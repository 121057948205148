const getTimeStamp = () => {
	return Date.now();
};

const getUppyLogger = () => ({
	debug: (...args: any) => console.debug(`[Uppy] [${getTimeStamp()}]`, ...args),
	warn: (...args: any) => console.warn(`[Uppy] [${getTimeStamp()}]`, ...args),
	error: (...args: any) => console.error(`[Uppy] [${getTimeStamp()}]`, ...args),
});

export default getUppyLogger;
